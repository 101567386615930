<template>
  <div>
    <div class="block-button-filter">
      <div
        @click="changeValue"
        class="button-custom-white"
        :class="value ? 'open-filter' : ''"
        :style="disabled ? 'pointer-events: none; background: var(--app)' : ''"
      >
        <v-icon
          :style="disabled ? 'opacity: 0.4' : ''"
          v-if="!selectedItems"
          size="16"
        >
          mdi-filter
        </v-icon>
        <div
          :style="disabled ? 'opacity: 0.4' : ''"
          class="button-custom-number"
          v-else
        >
          {{ selectedItems }}
        </div>
        <span :style="disabled ? 'opacity: 0.4' : ''">Фильтры</span>
      </div>
      <transition name="slideDown">
        <div v-if="value" class="button-filter-line">
          <svg
            class="filter-line-corner"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g transform="translate(-204.000000, -193.000000)" fill="#FFFFFF">
                <g transform="translate(88.000000, 159.000000)">
                  <path
                    d="M124,34 C124,38.418278 127.581722,42 132,42 C127.581722,42 124,45.581722 124,50 C124,45.581722 120.418278,42 116,42 C120.418278,42 124,38.418278 124,34 Z"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </transition>
    </div>

    <div class="block-filter">
      <transition name="slideDown">
        <div class="filter-card" v-if="value">
          <slot name="mainItems">
            <div class="filter-card-items">
              <div v-for="(i, index) in items" :key="index" class="card-item">
                <slot v-if="i.type == 'select'" :name="i.slotName">
                  <SelectInput
                    :items="i.items"
                    :label="i.label"
                    v-model="i.value"
                    :valid="i.valid"
                    :itemText="i.itemText"
                    :itemValue="i.itemValue"
                    :multiple="i.multiple"
                    @input="i.vmodel"
                  />
                </slot>

                <slot v-else :name="i.slotName"> </slot>
              </div>
            </div>
          </slot>
          <div>
            <slot name="bottomButtons"></slot>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import SelectInput from "@/components/main/inputs/SelectInput";

export default {
  components: {
    SelectInput,
  },
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedItems: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({}),
  methods: {
    changeValue() {
      this.$emit("input", !this.value);
    },
  },
  computed: {},
  mounted() {},
  created() {},
  watch: {},
};
</script>
<style scoped lang="scss">
.button-custom-white {
  min-width: 94px !important;
  height: 34px !important;
  background: var(--app-white) !important;
  border-radius: 8px !important;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  user-select: none;
  cursor: pointer;
  span {
    letter-spacing: 0.23px !important;
    color: var(--app-blue) !important;
    font-size: 14px !important;
    font-weight: 500;
    text-transform: initial;
  }
  i {
    margin-right: 4px;
    color: var(--app-blue) !important;
  }
  .button-custom-number {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--app-blue);
    color: var(--app-white);
    font-size: 10px;
    font-weight: 600;
    margin-right: 6px;
  }
}
.block-button-filter {
  position: relative;
}
.block-filter {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  z-index: 10;
  overflow: hidden;
}
.filter-card {
  margin-top: 16px;
  width: 100%;
  height: auto;
  background: var(--app-white);
  border-radius: 16px;
  box-shadow: 0px 0px 34px 2px rgba(230, 230, 239, 0.7);
  transition: 0.2s;
  .filter-card-items {
    padding-top: 32px;
    padding-right: 32px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .card-item {
      width: 270px;
      margin-left: 32px;
      margin-right: 4px;
    }
  }
  // .filter-card-button {
  //   padding: 24px;
  // }
}
.button-filter-line {
  width: 100%;
  height: 28px;
  background: var(--app-white);
  position: absolute;
  left: 0;
  z-index: 11;
  top: 34px;
}
.filter-line-corner {
  position: absolute;
  right: -8px;
  top: 8px;
}
.open-filter {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  transition: 0.2s ease;
  // align-items: flex-start !important;
}
.slideDown-enter-active {
  animation: slideDown 0.2s;
}
.slideDown-leave-active {
  opacity: 0;
  animation: slideDown 0.2s reverse;
}
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
