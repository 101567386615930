<template>
  <div v-if="loading" class="page-container">
    <div class="container-title-block">
      <div class="title-block-text">Пользователи</div>
      <div class="title-block-search">
        <SearchInput class="ml-4" v-model="search" label="Поиск пользователя" />
      </div>
    </div>
    <div class="container-subtitle-block">
      <div class="d-flex mb-3">
        <FilterBlock
          v-model="filterOpen"
          class="mr-4"
          :selectedItems="selectedFilterNumber"
          :disabled="checkFilterItems"
        >
          <template v-slot:mainItems>
            <div class="filter-box">
              <div class="filter-box-title" v-if="tags.arrValue.length > 0">
                Теги по значению
              </div>
              <div class="filter-box-items" v-if="tags.arrValue.length > 0">
                <SelectInput
                  class="box-items-select"
                  v-for="(tag, index) in tags.arrValue"
                  :key="index"
                  :items="tag.values"
                  :label="tag.name"
                  itemText="value"
                  itemValue="id"
                  v-model="tag.selectedFilter"
                  :hideDetails="true"
                  multiple
                ></SelectInput>
              </div>
              <div class="filter-box-title" v-if="filterDateItems.length > 0">
                Теги по дате
              </div>
              <div
                class="filter-box-date-container"
                v-for="(tag, index) in filterDateItems"
                :key="index"
              >
                <SelectInput
                  style="
                    max-width: 300px !important;
                    min-width: 300px !important;
                  "
                  :items="tag.items"
                  class="pb-6"
                  label="Тег"
                  itemText="name"
                  itemValue="id"
                  @change="selectFilterDateTag(tag)"
                  v-model="tag.selected"
                  :clearable="false"
                  disabled
                  :hideDetails="true"
                ></SelectInput>
                <SelectInput
                  style="
                    max-width: 120px !important;
                    min-width: 120px !important;
                  "
                  class="ml-5"
                  v-show="tag.selected"
                  :items="typesChoiseDateInput"
                  label="Тип"
                  itemText="name"
                  @change="changeTypeDate(tag.selectObj)"
                  v-model="tag.selectObj.typeChoise"
                  itemValue="value"
                  :clearable="false"
                  :hideDetails="true"
                ></SelectInput>

                <div
                  v-if="tag.selectObj.typeChoise === 'date'"
                  class="date-container-block"
                >
                  <div class="date-container-text">ОТ</div>

                  <DateInput
                    label="Дата"
                    :hideDetails="true"
                    v-model="tag.selectObj.from.value"
                    clearable
                  ></DateInput>
                </div>
                <div
                  v-if="tag.selectObj.typeChoise === 'date'"
                  class="date-container-block"
                >
                  <div class="date-container-text">До</div>

                  <DateInput
                    label="Дата"
                    :hideDetails="true"
                    v-model="tag.selectObj.before.value"
                    clearable
                  ></DateInput>
                  <div
                    class="date-container-icon"
                    v-if="tag.selected"
                    @click="deleteFilterTag(tag)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </div>
                </div>
                <div
                  v-if="tag.selectObj.typeChoise === 'period'"
                  class="date-container-block"
                >
                  <div class="date-container-text">ОТ</div>
                  <div class="d-flex">
                    <SelectInput
                      :items="typesSelectedDateInput"
                      label="Период"
                      itemText="name"
                      v-model="tag.selectObj.from.type"
                      @change="tag.selectObj.from.value = ''"
                      itemValue="value"
                      :hideDetails="true"
                    ></SelectInput>
                    <TextInput
                      class="ml-4"
                      label="Кол-во"
                      type="number"
                      :hideDetails="true"
                      v-model="tag.selectObj.from.value"
                    ></TextInput>
                  </div>
                </div>
                <div
                  v-if="tag.selectObj.typeChoise === 'period'"
                  class="date-container-block"
                >
                  <div class="date-container-text">До</div>
                  <div class="d-flex">
                    <SelectInput
                      :items="typesSelectedDateInput"
                      label="Период"
                      :hideDetails="true"
                      itemText="name"
                      @change="tag.selectObj.before.value = ''"
                      v-model="tag.selectObj.before.type"
                      itemValue="value"
                    ></SelectInput>
                    <TextInput
                      class="ml-4"
                      label="Кол-во"
                      type="number"
                      :hideDetails="true"
                      v-model="tag.selectObj.before.value"
                    ></TextInput>
                  </div>
                  <div
                    class="date-container-icon"
                    @click="deleteFilterTag(tag)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </div>
                </div>
                <div
                  class="date-container-icon"
                  v-if="tag.selected && !tag.selectObj.typeChoise"
                  @click="deleteFilterTag(tag)"
                >
                  <v-icon>mdi-close</v-icon>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:bottomButtons>
            <div class="filter-bottom">
              <v-btn class="button-grey-blue mr-8" depressed @click="resetTags"
                >Сбросить выбранные теги</v-btn
              >
              <v-btn class="button-blue" depressed @click="filterItems"
                >Применить</v-btn
              >
            </div>
          </template>
        </FilterBlock>

        <v-btn
          depressed
          class="button-white-blue mr-4"
          :disabled="selectedItems.length == 0"
          @click="openDialogGroup('create')"
        >
          Создать группу
        </v-btn>
        <v-btn
          depressed
          :disabled="
            selectedFilterTagsList.length == 0 || selectedItems.length == 0
          "
          class="button-white-blue mr-4"
          @click="openDialogGroup('smart')"
        >
          Создать SMART-группу
        </v-btn>
        <v-btn
          depressed
          class="button-white-blue mr-4"
          :disabled="selectedItems.length == 0"
          @click="openDialogGroup('add')"
        >
          Добавить в группу
        </v-btn>
      </div>
      <div class="d-flex mb-3">
        <v-btn depressed @click="dialogAddUser = true" class="button-blue mr-4">
          Добавить пользователя
        </v-btn>
        <v-btn disabled depressed class="button-green">
          Импортировать пользователей
        </v-btn>
      </div>
    </div>
    <div class="container-table">
      <Table
        :items="itemsAll"
        :headers="usersHeaders"
        headerFixed
        @click:row="onClickRowEmployee"
        :noClickSlots="['checkbox']"
      >
        <template v-slot:[`header.checkbox`]>
          <CheckBox
            v-model="allChecked"
            :main="true"
            @change="clickHeaderCheckBox"
            :background="true"
            color="#0078FF"
          />
        </template>
        <template v-slot:[`item.checkbox`]="{ item }">
          <CheckBox
            v-model="item.checkbox"
            @change="checkboxMeth($event, item)"
            color="#0078FF"
          />
        </template>
        <template v-slot:[`item.fullName`]="{ item }">
          <template v-if="item.fullName">
            {{ item.fullName }}
          </template>
          <template v-else-if="item.email">
            {{ item.email }}
          </template>
          <template v-else-if="item.phoneNumber">
            {{ item.phoneNumber | VMask("+# (###) ### ##-##") }}
          </template>
        </template>
      </Table>
    </div>
    <Dialog
      v-model="dialogAddUser"
      titleText="Добавление пользователя"
      :refForm="'formUser'"
      @click:success="addEmployee"
      :buttonsFooter="{
        success: {
          title: 'Добавить пользователя',
          loading: true,
        },
      }"
      :mainItems="[
        {
          type: 'image',
          label: 'Фото профиля',
          value: user.photo,
          vmodel: (e) => (user.photo = e),
        },
        {
          type: 'text',
          label: 'Фамилия',
          valid: true,
          value: user.lastName,
          vmodel: (e) => (user.lastName = e),
        },
        {
          type: 'text',
          label: 'Имя',
          valid: true,
          value: user.firstName,
          vmodel: (e) => (user.firstName = e),
        },
        {
          type: 'text',
          label: 'Отчество',
          valid: false,
          value: user.middleName,
          vmodel: (e) => (user.middleName = e),
        },
        {
          slotName: 'email',
        },
        {
          slotName: 'phone',
        },
      ]"
    >
      <template v-slot:email>
        <TextInput
          v-model="user.email"
          :errorMessages="mailAlreadyExists"
          label="Электронная почта"
          type="mail"
          valid
        ></TextInput>
      </template>
      <template v-slot:phone>
        <PhoneInput
          v-model="user.phoneNumber"
          :errorMessages="phoneAlreadyExists"
          label="Телефон"
        ></PhoneInput>
      </template>
    </Dialog>
    <Dialog
      v-model="dialogGroup"
      :titleText="group.titleDialog"
      @closeDialog="closeDialogGroup"
      ref="dialogGroup"
      :refForm="'formGroup'"
    >
      <template v-slot:blockMain>
        <TextInput
          v-if="group.type == 'create' || group.isSmart"
          v-model="group.title"
          :errorMessages="mailAlreadyExists"
          label="Название группы"
          valid
        ></TextInput>
        <SelectInput
          v-else-if="group.type == 'add'"
          :items="groups"
          label="Название группы"
          v-model="group.id"
          valid
          itemText="title"
          itemValue="id"
        ></SelectInput>
        <div
          style="max-height: calc(100vh - 800px); overflow: auto"
          v-if="group.type == 'smart'"
        >
          <div v-for="(tag, index) in selectedFilterTagsList" :key="index">
            <div class="dialog-card-teg-name">
              {{ tag.information.nameTag }}
            </div>
            <div class="dialog-card-teg">
              <div
                class="card-teg-value"
                v-for="(val, index) in tag.information.valueNames"
                :key="index"
              >
                <span>{{ val }} </span>
                <span
                  v-if="
                    tag.tagType === 'value' &&
                    index + 1 < tag.information.valueNames.length
                  "
                  >,&nbsp;</span
                >
                <span v-else>&nbsp;</span>
              </div>
            </div>
          </div>
        </div>

        <div style="height: 300px">
          <Table :items="selectedItems" :headers="dialogHeaders" headerHidden>
            <template v-slot:[`item.checkbox`]="{ item }">
              <CheckBox v-model="item.checkbox" color="#0078FF" />
            </template>
            <template v-slot:[`item.fullName`]="{ item }">
              <template v-if="item.fullName">
                {{ item.fullName }}
              </template>
              <template v-else-if="item.email">
                {{ item.email }}
              </template>
              <template v-else-if="item.phoneNumber">
                {{ item.phoneNumber }}
              </template>
            </template>
          </Table>
          <v-divider></v-divider>
        </div>
      </template>
      <template v-slot:blockFooter>
        <div style="color: #707070">
          Выбранно: {{ selectedItems.filter((i) => i.checkbox).length }}
        </div>
        <v-btn
          :loading="loadingDialogButton"
          @click="sendGroup"
          depressed
          class="button-blue"
          :disabled="selectedItems.filter((i) => i.checkbox).length == 0"
        >
          {{ group.textButton }}
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<script>
import SearchInput from "@/components/main/inputs/SearchInput";
import FilterBlock from "@/components/main/FilterBlock";
import CheckBox from "@/components/main/inputs/CheckBox.vue";
import PhoneInput from "@/components/main/inputs/PhoneInput.vue";
import TextInput from "@/components/main/inputs/TextInput.vue";
import DateInput from "@/components/main/inputs/DateInput.vue";

export default {
  components: {
    SearchInput,
    FilterBlock,
    CheckBox,
    PhoneInput,
    TextInput,
    DateInput,
  },
  data: () => ({
    loading: false,
    search: "",
    filterOpen: false,
    selectedItems: [],
    itemsAll: [],
    items: [],
    user: { email: "", phoneNumber: "" },
    mailAlreadyExists: "",
    phoneAlreadyExists: "",
    dialogAddUser: false,
    dialogGroup: false,
    allChecked: false,
    usersHeaders: [
      {
        align: "flex-left",
        sortable: false,
        value: "checkbox",
      },
      {
        text: "ФИО",
        align: "flex-left",
        sortable: false,
        value: "fullName",
      },
    ],
    dialogHeaders: [
      {
        align: "flex-left",
        sortable: false,
        value: "checkbox",
      },
      {
        align: "flex-left",
        sortable: false,
        value: "fullName",
      },
    ],
    group: {
      type: "",
      title: "",
      titleDialog: "",
      textButton: "",
      isSmart: false,
      id: "",
    },
    groups: [],
    loadingDialogButton: false,
    tags: {
      arrValue: [],
      arrDate: [],
    },
    tagsAll: [],

    filterIdTags: [],
    selectedFilterTagsList: [],
    selectedFilterNumber: 0,
    tagsValuesId: [],
    filterDateItems: [],
    typesChoiseDateInput: [
      {
        name: "Дата",
        value: "date",
      },
      {
        name: "Период",
        value: "period",
      },
    ],
    typesSelectedDateInput: [
      {
        name: "День",
        value: "day",
        valueMs: 86400000,
      },
      {
        name: "Неделя",
        value: "week",
        valueMs: 604800000,
      },
      {
        name: "Месяц",
        value: "month",
        valueMs: 2592000000,
      },

      {
        name: "Год",
        value: "year",
        valueMs: 31536000000,
      },
    ],
  }),
  computed: {
    checkFilterItems() {
      if (this.tags.arrDate.length > 0 || this.tags.arrValue.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async getItems() {
      const tags = this.selectedFilterTagsList.map((i) => {
        const newObj = { ...i };
        delete newObj.information;
        return newObj;
      });
      await this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/user/list",
          {
            tag: tags,
            search: this.search != null ? this.search : "",
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.itemsAll = res.data;
          this.allChecked = false;
          let concatArr = [];
          if (this.itemsAll.length > 0) {
            this.itemsAll.forEach((element) => {
              element.checkbox = false;
              element.fullName =
                (element.lastName ? element.lastName + " " : "") +
                (element.firstName ? element.firstName + " " : "") +
                (element.middleName ? element.middleName : "");
              if (element.tagDates.length > 0) {
                concatArr = element.tagValues.concat(element.tagDates);
                element.tagValues = concatArr;
              }
              if (element.tagValues.length > 0) {
                element.tagValues.forEach((el) => {
                  if (!element[el.tag.id]) {
                    if (el.tag && el.tag.tagType == "date") {
                      el.value = this.$moment(el.date).format("DD.MM.YYYY");
                    }
                    element[el.tag.id] = el.value;
                  }
                });
              }
              if (element.tagValues && element.tagValues.length > 0) {
                element.tagValues.forEach((tagVal) => {
                  if (
                    !this.usersHeaders.some((e) => e.text === tagVal.tag.name)
                  ) {
                    this.usersHeaders.push({
                      text: tagVal.tag.name,
                      idTag: tagVal.tag.id,
                      align: "flex-left",
                      sortable: false,
                      value: tagVal.tag.id,
                    });

                    this.filterIdTags.push(tagVal.tag.id);
                  }
                });
              }
            });
          }
          // this.fiftyItems();
          this.loading = true;
        })
        .catch((err) => {
          this.loading = false;
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    fiftyItems() {
      this.itemsAll.forEach((i) => {
        i.checkbox = false;
      });
      if (this.itemsAll.length < 50) {
        this.items = this.itemsAll;
      } else {
        // for (let i = 0; i < 50; i++) {
        //   const element = this.itemsAll[i];
        //   this.items.push(element);
        // }
        const itemsFifty = JSON.parse(JSON.stringify(this.itemsAll));
        itemsFifty.length = 50;
        this.items = itemsFifty;
      }
    },
    getGroups() {
      this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/group/list", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.groups = res.data
            .map((i) => {
              if (!i.group.isSmart) {
                return i.group;
              }
            })
            .filter((i) => i);
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async getTags() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/tag/list", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.tagsAll = JSON.parse(JSON.stringify(res.data));
          const newArr = res.data.filter((i) => {
            if (this.filterIdTags.find((f) => f == i.id)) {
              if (i.tagType == "value") {
                i.selectedFilter = [];
                return i;
              } else if (i.tagType == "date") {
                return i;
              }
            }
          });

          this.tags = {
            arrValue: newArr.filter((i) => i.tagType == "value"),
            arrDate: newArr.filter((i) => i.tagType == "date"),
          };
          if (this.tags.arrDate.length > 0) {
            this.filterDateItems.push({
              id: (~~(Math.random() * 1e8)).toString(16),
              items: this.tags.arrDate,
              selected: "",
              selectObj: {
                typeChoise: "",
                from: {
                  type: "",
                  value: "",
                },
                before: {
                  type: "",
                  value: "",
                },
              },
            });
          }
        })

        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    checkboxMeth(bool, item) {
      if (bool) {
        this.selectedItems.push({ ...item, checkbox: true });
      } else {
        this.selectedItems = this.selectedItems.filter((v) => v.id != item.id);
      }
      this.selectedItems.length == this.itemsAll.length
        ? (this.allChecked = true)
        : (this.allChecked = false);
    },
    onClickRowEmployee(row) {
      this.$router.push({ name: "User", params: { id: row.id } });
    },
    clickHeaderCheckBox(bool) {
      this.selectedItems = [];
      this.itemsAll.forEach((element) => {
        element.checkbox = bool;
        if (bool) {
          this.selectedItems.push({ ...element, checkbox: true });
        }
      });
    },
    // scrollMethod(e) {
    //   const scrollTop = e.target.scrollTop;
    //   const scrollHeight = e.target.scrollHeight - e.target.clientHeight;
    //   if (scrollTop >= scrollHeight - 1000) {
    //     this.sliceConcat();
    //   }
    // },
    // sliceConcat() {
    //   console.log(this.itemsAll.length , this.items.length);
    //   if (this.itemsAll.length !== this.items.length) {
    //     let arrSliced = this.itemsAll.slice(this.items.length);
    //     this.items = this.items.concat(arrSliced);
    //   }
    // },
    addEmployee(method) {
      let formData = new FormData();
      formData.append("firstName", this.user.firstName?.trim());
      if (this.user.middleName) {
        formData.append("middleName", this.user.middleName?.trim());
      } else {
        formData.append("middleName", "");
      }
      formData.append("lastName", this.user.lastName?.trim());
      if (this.user.phoneNumber.length == 11) {
        formData.append("phoneNumber", this.user.phoneNumber);
      }

      formData.append("email", this.user.email);
      formData.append("photo", this.user.photo);
      this.$axios
        .post(this.$store.getters.apiAdminPanelV4 + "/user", formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then(() => {
          this.$store.commit("successSet", true);
          // this.$store.commit(
          //   "successTextSet",
          //   `Пароль отправлен на почту ${this.user.email} `
          // );
          this.getItems();
          method();
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err.response.data.result);
        });
    },
    checkDuplicate(data, type) {
      this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/user/login/check", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            data: data,
          },
        })
        .then(() => {
          if (type == "phone") {
            this.phoneAlreadyExists = "";
          } else {
            this.mailAlreadyExists = "";
          }
        })
        .catch((err) => {
          if (err.response.data.result == "user_already_exists") {
            if (type == "phone") {
              this.phoneAlreadyExists = "Такой номер уже зарегистрирован";
            } else {
              this.mailAlreadyExists = "Такая почта уже зарегистрирована";
            }
          } else {
            this.$store.commit("errorSet", true);
            this.$store.commit("errorTextSet", err);
          }
        });
    },
    openDialogGroup(type) {
      switch (type) {
        case "create":
          this.group = {
            type: "create",
            titleDialog: "Создание группы",
            textButton: "Создать группу",
            isSmart: false,
            title: "",
          };
          break;
        case "add":
          this.group = {
            type: "add",
            titleDialog: "Добавление группы",
            textButton: "Добавить в группу",
            id: "",
            isSmart: false,
          };
          break;
        case "smart":
          this.group = {
            type: "smart",
            titleDialog: "Создание smart-группы",
            textButton: "Создать smart-группу ",
            isSmart: true,
            title: "",
          };
          break;

        default:
          break;
      }
      this.dialogGroup = true;
    },
    closeDialogGroup() {
      this.selectedItems = this.selectedItems.filter((i) => i.checkbox);
      this.itemsAll.forEach((element) => {
        if (this.selectedItems.find((i) => i.id == element.id)) {
          element.checkbox = true;
        } else {
          element.checkbox = false;
        }
      });
      this.selectedItems.length == this.itemsAll.length
        ? (this.allChecked = true)
        : (this.allChecked = false);
    },
    sendGroup() {
      if (
        this.$refs.dialogGroup &&
        this.$refs.dialogGroup.$refs.formGroup &&
        this.$refs.dialogGroup.$refs.formGroup.validate()
      ) {
        const tagsSelected = this.selectedItems
          .map((i) => (i.checkbox ? i.id : ""))
          .filter((i) => i);
        this.loadingDialogButton = true;
        if (this.group.type == "create" || this.group.type == "smart") {
          let tagsValuesId = [];
          if (this.group.type == "smart") {
            this.tagsValuesId.forEach((element) => {
              element.valuesIds.forEach((el) => {
                tagsValuesId.push(el);
              });
            });
          }
          this.$axios
            .post(
              this.$store.getters.apiAdminPanelV4 + "/group",
              {
                title: this.group.title,
                isSmart: this.group.isSmart,
                tagsValuesId: tagsValuesId.length > 0 ? tagsValuesId : "",
              },
              {
                headers: {
                  authorization: this.$store.getters.adminPanelHeaderAuth,
                },
              }
            )
            .then((res) => {
              this.addUsersInGroup(res.data.id, tagsSelected);
            })
            .catch((err) => {
              this.loadingDialogButton = false;
              this.$store.commit("errorSet", true);
              this.$store.commit("errorTextSet", err);
            });
        } else {
          this.addUsersInGroup(this.group.id, tagsSelected);
        }
      }
    },
    addUsersInGroup(id, list) {
      this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/group/users",
          {
            id: id,
            usersIds: list,
            toAdd: true,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.loadingDialogButton = false;
          this.getItems();
          this.getGroups();
          this.dialogGroup = false;
          this.clickHeaderCheckBox(false);
          this.resetTags();
          this.selectedItems = [];
        })
        .catch((err) => {
          this.loadingDialogButton = false;
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    filterItems() {
      let tags = [];
      this.tags.arrValue.forEach((element) => {
        if (
          element &&
          element.selectedFilter &&
          element.selectedFilter.length > 0
        ) {
          element.selectedFilter.forEach((e) => {
            if (tags.find((i) => i.tagId === element.id)) {
              tags.forEach((i) => {
                if (i.tagId === element.id) {
                  i.value = [i.value];
                  i.value.push(e);
                  i.information.valueNames.push(
                    element.values.find((i) => i.id == e).value
                  );
                }
              });
            } else {
              tags.push({
                tagId: element.id,
                tagType: "value",
                value: e,
                information: {
                  nameTag: element.name,
                  valueNames: [element.values.find((i) => i.id == e).value],
                },
              });
            }
          });
        }
      });
      this.filterDateItems.forEach((element) => {
        if (element.selected) {
          let newObj = {
            tagId: element.selected,
            tagType: "date",
            dateType: element.selectObj ? element.selectObj.typeChoise : "",
            from: element.selectObj ? element.selectObj.from.value : "",
            to: element.selectObj ? element.selectObj.before.value : "",
          };
          if (
            element.selectObj &&
            element.selectObj.typeChoise &&
            element.selectObj.typeChoise == "period"
          ) {
            newObj.periodTypeFrom = element.selectObj.from.type
              ? element.selectObj.from.type
              : "";
            newObj.periodTypeTo = element.selectObj.before.type
              ? element.selectObj.before.type
              : "";

            const fromWord = this.getWord(newObj.periodTypeFrom, newObj.from);
            const toWord = this.getWord(newObj.periodTypeTo, newObj.to);
            const from = newObj.periodTypeFrom
              ? `от ${newObj.from} ${fromWord} `
              : "";
            const to = newObj.periodTypeTo ? `до ${newObj.to} ${toWord} ` : "";
            newObj.information = {
              nameTag: element.items.find((i) => i.id == element.selected).name,
              valueNames: [from, to],
            };
          } else {
            newObj.information = {
              nameTag: element.items.find((i) => i.id == element.selected).name,
              valueNames: [
                element.selectObj.from.value
                  ? `от ${this.$moment(
                      parseInt(element.selectObj.from.value)
                    ).format("DD.MM.YYYY")}`
                  : "",
                element.selectObj.before.value
                  ? `до ${this.$moment(
                      parseInt(element.selectObj.before.value)
                    ).format("DD.MM.YYYY")}`
                  : "",
              ],
            };
          }
          if (newObj.from || newObj.to) {
            tags.push(newObj);
          }
        }
      });
      this.selectedFilterNumber = tags.length;
      this.selectedFilterTagsList = tags;
      Promise.all([this.getItems()]).then(() => {
        this.filterOpen = false;
      });
    },
    findTagMethod(id) {
      return this.tagsAll.find((i) => i.id === id);
    },
    resetTags() {
      this.tags.arrValue.map((i) => (i.selectedFilter = []));
      this.tags.arrDate.map((i) => (i.selectedFilter = []));
      this.filterDateItems = [];
      if (this.tags.arrDate.length > 0) {
        this.filterDateItems.push({
          id: (~~(Math.random() * 1e8)).toString(16),
          items: this.tags.arrDate,
          selected: "",
          selectObj: {
            typeChoise: "",
            from: {
              type: "",
              value: "",
            },
            before: {
              type: "",
              value: "",
            },
          },
        });
      }
      this.selectedFilterTagsList = [];
      this.tagsValuesId = [];
      this.selectedItems = [];
      this.allChecked = false;
      this.selectedFilterNumber = 0;
      this.getItems();
      this.filterOpen = false;
    },
    getWord(type, count) {
      const words =
        type == "day"
          ? ["дня", "дней", "дней"]
          : type == "week"
          ? ["недели", "недель", "недель"]
          : type == "month"
          ? ["месяца", "месяцев", "месяцев"]
          : type == "year"
          ? ["года", "лет", "лет"]
          : "";
      return this.declinationOfWords(count, words);
    },
    selectFilterDateTag(item) {
      if (this.filterDateItems.length < this.tags.arrDate.length) {
        this.filterDateItems.push({
          id: (~~(Math.random() * 1e8)).toString(16),
          selected: "",
          items: item.items.filter((i) => i.id != item.selected),
          selectObj: {
            typeChoise: "",
            from: {
              type: "",
              value: "",
            },
            before: {
              type: "",
              value: "",
            },
          },
        });
      }
    },
    deleteFilterTag(item) {
      this.filterDateItems = this.filterDateItems.filter(
        (i) => i.id != item.id
      );
      const newObj = this.tags.arrDate.find((i) => i.id == item.selected);
      this.filterDateItems.forEach((element) => {
        element.items.push({ ...newObj });
      });
      if (!this.filterDateItems.find((i) => !i.selected)) {
        this.filterDateItems.push({
          id: (~~(Math.random() * 1e8)).toString(16),
          selected: "",
          items: [newObj],
          selectObj: {
            typeChoise: "",
            from: {
              type: "",
              value: "",
            },
            before: {
              type: "",
              value: "",
            },
          },
        });
      }
    },
    changeTypeDate(item) {
      item.from.value = "";
      item.from.type = "";
      item.before.value = "";
      item.before.type = "";
    },
  },
  created() {
    new Promise((resolve) => {
      resolve(this.getItems());
    }).then(() => {
      this.getTags();
    });
    this.getGroups();
  },
  mounted() {},
  watch: {
    "user.email"() {
      this.mailAlreadyExists = "";
      if (this.user.email && this.user.email.length > 0) {
        if (this.validateEmail(this.user.email)) {
          this.checkDuplicate(this.user.email, "email");
        }
      }
    },
    "user.phoneNumber"() {
      if (this.user.phoneNumber.length === 11) {
        this.checkDuplicate(this.user.phoneNumber, "phone");
      }
    },
    search() {
      this.getItems();
    },
    // filterDateItems: {
    //   handler() {
    //     this.filterItems();
    //   },
    //   deep: true,
    // },
    // tags: {
    //   handler() {
    //     this.filterItems();
    //   },
    //   deep: true,
    // },
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  padding: 40px 64px 0px 64px;
  height: 100%;
  width: 100%;
  position: relative;
  .container-title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-block-text {
      color: var(--app-black);
      font-size: 28px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0px;
    }
    .title-block-search {
      width: 300px;
    }
  }
  .container-subtitle-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 40px 0px 0px 0px;
    position: relative;
  }

  .filter-box {
    max-height: 500px;
    overflow: auto;
    .filter-box-title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 16px;
      padding: 0px 24px;
    }
    .filter-box-items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-auto-rows: minmax(70px, 1fr);
      padding: 0px 14px;
      .box-items-select {
        margin: 0px 10px;
      }
    }
    .filter-box-title:first-child {
      padding-top: 24px;
    }
    .filter-box-date-container {
      display: flex;
      flex-wrap: wrap;
      padding: 0px 24px 0px 24px;
      .date-container-block {
        display: flex;
        flex: 1 1;
        min-width: 300px;
        margin-bottom: 24px;

        .date-container-text {
          color: var(--app-grey-3);
          text-transform: uppercase;
          font-weight: 500;
          margin: 10px 16px 0px 16px;
        }
      }
      .date-container-icon {
        margin-top: 10px;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
  .filter-bottom {
    padding: 0px 24px 24px 24px;
  }
  .container-table {
    height: calc(100vh - 250px);
  }
  .dialog-card-teg-name {
    text-transform: uppercase;
    font-size: 12px;
    color: var(--app-grey-3);
  }
  .dialog-card-teg {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .card-teg-value {
      span {
        font-size: 14px;
        color: var(--app-black);
      }
    }
  }
}
</style>
