<template>
  <v-text-field
    v-model="phonenumber"
    :label="label"
    :rules="checkRules"
    color="#0078FF"
    outlined
    v-mask="'+# (###) ### ##-##'"
    :placeholder="placeholder"
    :error-messages="errorMessages"
    :prepend-inner-icon="prependIcon"
    type="text"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    label: { type: String, default: "" },
    value: { type: String, default: "" },
    valid: { type: Boolean, default: false },
    prependIcon: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    errorMessages: { type: String, default: "" },
  },
  data: () => ({
    phonenumber: null,
  }),
  methods: {},
  computed: {
    checkRules() {
      if (this.valid) {
        return [
          (v) => !!v || "Обязательно к заполнению",
          (v) => (v && v.length > 17) || "Обязательно к заполнению",
        ];
      }
      return [];
    },
  },

  created() {
    if (this.value) {
      this.phonenumber = JSON.parse(JSON.stringify(this.value));
    }
  },

  watch: {
    phonenumber() {
      if (this.phonenumber) {
        const number = this.phonenumber.replace(/[\s.,%+)(-]/g, "");
        this.$emit("input", number);
      }
    },
  },
};
</script>
<style scoped lang="scss">
</style>
