<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        @input="$emit('input', $event)"
        outlined
        v-model="viewDate"
        :rules="checkRules"
        readonly
        :label="label"
        v-bind="attrs"
        v-on="on"
        :clearable="clearable"
        :hide-details="hideDetails"
      ></v-text-field>
    </template>
    <v-date-picker
      locale="ru-Ru"
      color="var(--app-blue)"
      v-model="date"
      first-day-of-week="1"
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    label: { type: String, default: "" },
    value: {},
    valid: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
  },
  data: () => ({
    menu: false,
    date: "",
    viewDate: "",
  }),
  computed: {
    checkRules() {
      if (this.valid) {
        return [(v) => !!v || "Обязательно к заполнению"];
      }
      return [];
    },
  },
  created() {
    if (this.value) {
      this.viewDate = this.formatDate(
        new Date(this.value).toISOString().substr(0, 10)
      );
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
  },
  watch: {
    date() {
      this.viewDate = this.formatDate(this.date);
      this.$emit("input", new Date(this.date).getTime());
      this.menu = false;
    },
  },
};
</script>